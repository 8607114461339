






















import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component({
  name: 'TabContent'
})
export default class TabContent extends Vue {
  @Prop({ default: null }) readonly title!: string;
}
