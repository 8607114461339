import Vue from 'vue';
import * as rules from 'vee-validate/dist/rules';
import { extend } from 'vee-validate';
import { Prop } from 'vue-property-decorator';
import BaseFormControlInt from './BaseFormControlInt';

export default class BaseFormControl extends Vue implements BaseFormControlInt {
  @Prop({ default: null }) id!: string;

  @Prop({ default: null }) label!: string;

  @Prop({ default: null }) type!: string;

  @Prop({ default: null }) placeholder!: string;

  @Prop({ default: null }) field!: string;

  @Prop({ default: false }) loading!: boolean;

  @Prop({ default: false }) disabled!: boolean;

  @Prop({ default: () => [] }) rules!: Array<string>;

  @Prop({ default: null }) helpTitle!: string;

  @Prop({ default: null }) helpContent!: string;

  public focused = false;

  get labelTitle(): string | null {
    if (!this.label || !this.label.length) {
      return null;
    }
    return this.isRequired() ? `${this.label} *` : this.label;
  }

  get applyRules(): string | number {
    return this.rules.length ? this.rules.join('|') : '';
  }

  isRequired(): boolean {
    return this.rules.includes('required');
  }

  validate(): boolean {
    const component: any = this.$refs[this.id];
    return component.validate();
  }

  created(): void {
    const allRules: any = rules;
    this.rules.forEach((rule) => {
      if (allRules[rule]) {
        extend(rule, allRules[rule]);
      }
    });
  }
}
