

























































import { Component, Prop } from 'vue-property-decorator';
import styleVariables from '../../styles/export.scss';
import BaseFormControl from '../../helpers/BaseFormControl';
import BaseControlWrapper from './BaseControlWrapper.vue';

@Component({
  name: 'BaseTextInput',
  components: {
    BaseControlWrapper
  }
})
export default class BaseTextInput extends BaseFormControl {
  @Prop({ default: 'off' }) autocomplete!: string;

  styleVariables = styleVariables;

  created(): void {
    super.created();
  }
}
