






































import { Component, Prop } from 'vue-property-decorator';
import Vue from 'vue';
import { MenuOption } from '@improve/common-utils/src/types/MenuOption';

@Component({
  name: 'BaseMenu'
})
export default class BaseMenu extends Vue {
  @Prop({ default: 'default-menu-style' }) readonly cssClass!: string;

  @Prop({ default: () => [] }) readonly menuOptions!: Array<MenuOption>;

  @Prop({ default: 25 }) readonly nudgeRight!: number;

  @Prop({ default: true }) readonly ripple!: boolean;

  onOptionClick(option: MenuOption): void {
    this.$emit('input', option);
  }
}
